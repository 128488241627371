// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CenteredGrid } from '../CenteredGrid';

/**
 * Section Header
 * @param {object} props
 * @returns Component
 */
const SectionHeader = ({ props, color }) => {
  const { title, slogan, description } = props;
  return (
    <Box sx={{ padding: 6, pb: 2 }}>
      <Grid container>
        <CenteredGrid>
          <Typography variant='h5' gutterBottom>
            {title}
          </Typography>
        </CenteredGrid>
        <CenteredGrid>
          <Typography variant='h4' sx={{ color: color }} gutterBottom>
            {slogan}
          </Typography>
        </CenteredGrid>
        <Grid item>
          {description && (
            <Typography sx={{ mt: 2, textAlign: 'justify', color: color }}>
              {description}
            </Typography>
          )}
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
};
SectionHeader.propTypes = {
  props: PropTypes.object,
  color: PropTypes.string,
};

SectionHeader.defaultProps = {
  props: {
    title: '',
    slogan: '',
    description: null,
  },
  color: 'black',
};

export default SectionHeader;
