// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../../assets/img/rosmIcon.svg';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

/**
 * Footer Section
 * @param {object} props
 * @returns Component
 */
const Footer = (props) => {
  const { links, scrollToSection, isSecondaryPage, config } = props.props;
  const { footerContent, registryLink, logoText, opsecNumber } = config;
  const theme = useTheme();

  const orange = theme.palette.orange;
  const oliveGreen = theme.palette.oliveGreen;
  const oliveGreenFade = theme.palette.oliveGreenFade;
  const lightOliveGreen = theme.palette.lightOliveGreen;

  const navigate = useNavigate();

  const handleScrollToSection = (event) => {
    if (isSecondaryPage) {
      navigate(`/${event.target.id}`);
    } else {
      scrollToSection(event);
    }
  };

  const linkStyle = {
    fontWeight: 300,
    fontSize: 12,
    color: theme.palette.orange,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
    },
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <Box
      sx={{
        p: 6,
        pt: 4,
        pb: 4,
        borderTop: '1px solid #ADADAD',
        backgroundColor: oliveGreenFade,
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          md={2}
          sm={12}
          container
          alignItems='center'
          justifyContent='center'
        >
          <img src={logo} alt={logoText} width='29' />
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          container
          alignItems='center'
          justifyContent='center'
        >
          {links.map((link) => (
            <Button
              textalign='center'
              key={`foot${link}`}
              id={link}
              onClick={handleScrollToSection}
              disableRipple
              disableFocusRipple
              disableElevation
              sx={{
                color: '#FFF',
                '&:hover': {
                  backgroundColor: orange,
                  color: '#FFF',
                },
              }}
            >
              {link}
            </Button>
          ))}
          <Button
            textalign='center'
            key={`footFAQ`}
            id='FAQ'
            onClick={handleScrollToSection}
            sx={{
              color: '#FFF',
              '&:hover': {
                backgroundColor: orange,
                color: '#FFF',
              },
            }}
            component='a'
            href='/faq'
            disableRipple
            disableFocusRipple
            disableElevation
          >
            F.A.Q.
          </Button>
        </Grid>
        <Grid
          item
          md={2}
          sm={12}
          container
          alignItems='center'
          justifyContent='center'
        >
          <Button
            variant='orangeContained'
            startIcon={<LoginIcon />}
            component='a'
            href={registryLink}
            sx={{ maxHeight: '35px', fontSize: 10 }}
          >
            Registry Login
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item md={2} sm={3}></Grid>
        <Grid
          item
          md={8}
          sm={12}
          container
          alignItems='center'
          justifyContent='center'
        >
          <Stack spacing={1} sx={{ textAlign: 'center', mt: 3 }}>
            <b>{currentYear}</b>
            <Typography sx={{ fontWeight: 300, fontSize: 12 }}>
              {footerContent}
            </Typography>
            {opsecNumber && (
              <Typography sx={{ fontWeight: 300, fontSize: 12 }}>
                <b>OPSEC #:</b> {opsecNumber}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid
          item
          md={2}
          sm={12}
          container
          alignItems='center'
          justifyContent='center'
        >
          <IconButton
            aria-label='Back To Top'
            sx={{ color: '#000', mt: 3 }}
            disableRipple
          >
            <Grid container justifyContent='flex-end'>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='center'
              >
                <ArrowCircleUpIcon
                  sx={{ color: theme.palette.orange, fontSize: 34 }}
                  onClick={handleScrollToSection}
                  id='Home'
                  key='Home'
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='center'
                sx={{
                  textAlign: 'center',
                  fontSize: 10,
                  pt: 0.5,
                  color: 'white',
                }}
                onClick={handleScrollToSection}
                id='Home'
                key='Home'
              >
                Back to Top
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
