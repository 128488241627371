// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

/**
 * Hero Section
 * @param {object} props
 * @returns Component
 */
const Hero = (props) => {
  const { scrollToSection, shrinkSlogan, sectionRefs, config } = props.props;
  const { styles, registryLink, name } = config;
  const theme = useTheme();
  return (
    <Box sx={{ ...styles.hero, bgcolor: 'white' }} ref={sectionRefs['Home']}>
      <Paper
        component={Stack}
        direction='column'
        justifyContent='center'
        sx={{ height: '100%' }}
        elevation={0}
      >
        <Grid container sx={{ p: 6 }} spacing={8}>
          <Grid item md={8} sm={12}>
            <Stack spacing={1}>
              <Typography variant={shrinkSlogan ? 'h4' : 'h3'}>
                Rapidly Develop Production Prototype Systems.&nbsp;
                <Typography
                  variant='inherit'
                  component='span'
                  sx={styles.heroAltText}
                >
                  Less Cost, Less Risk
                </Typography>
              </Typography>
              <Typography variant={shrinkSlogan ? 'body3' : 'subtitle1'}>
                {name} provides a trusted community of government and industry
                contributors.
              </Typography>
              <Typography>
                <Button
                  component='a'
                  href={registryLink}
                  target='_blank'
                  variant='orangeContained'
                  sx={{ mr: 4, mt: 2, pl: 4, pr: 4 }}
                  startIcon={<LoginIcon />}
                >
                  Registry Login
                </Button>
                <Button
                  variant='orangeOutlined'
                  sx={{ mt: 2, pl: 4, pr: 4 }}
                  onClick={scrollToSection}
                  id='Access'
                >
                  Get Access
                </Button>
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={4} sm={6} sx={shrinkSlogan ? { display: 'none' } : {}}>
            <img
              src={'./assets/img/hero/slider2.jpg'}
              alt='Rapidly Production Prototype Systems'
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Hero;
