// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import parse from 'html-react-parser';
import Flags from 'country-flag-icons/react/3x2';

/** Partner Logos */
import AGVRA from '../../assets/img/partners/logo-AGVRA.png';
import GVSC from '../../assets/img/partners/logo-GVSC.png';
import NAMC from '../../assets/img/partners/logo-NAMC.png';
import ROS from '../../assets/img/partners/logo-ROS.png';
import SpaceROS from '../../assets/img/partners/logo-SpaceROS.png';
import ARMY from '../../assets/img/partners/logo-ARMY.png';
import Neya from '../../assets/img/partners/logo-Neya.png';
import PMFP from '../../assets/img/partners/logo-PMFP.png';

/** Partner Logos Reference for Display */
const images = {
  AGVRA,
  GVSC,
  NAMC,
  ROS,
  SpaceROS,
  ARMY,
  Neya,
  PMFP,
};

/**
 * Partners Section
 * @param {object} props
 * @returns Component
 */
const Partners = (props) => {
  const { sectionRefs, config } = props.props;
  const { partnerType, partners, partnerSlogan, partnerDescription } = config;
  const theme = useTheme();
  const orange = theme.palette.orange;
  const navyBlue = theme.palette.navyBlue;
  const localProps = {
    title: partnerType === 'standard' ? 'Organizations' : 'Partners',
    slogan: partnerSlogan,
    description: partnerDescription,
  };

  const Partner = ({ partner, row }) => {
    const bgColor = row === 3 ? 'white' : navyBlue;
    const borderColor = row === 3 ? 'black' : navyBlue;
    /* Paper Style */
    const paperStyle = {
      bgcolor: bgColor,
      border: `1px solid ${borderColor}`,
      p: 1,
      borderRadius: '15px',
      width: '245px',
      height: '72px',
    };
    const linkStyle = {
      fontWeight: 600,
      color: orange,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
        color: 'black',
      },
    };
    return (
      <>
        <Stack
          sx={{ p: 2 }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Paper
            elevation={0}
            sx={paperStyle}
            component={Stack}
            direction='column'
            justifyContent='center'
          >
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              component='a'
              href={`https://${partner?.link}`}
              target='_blank'
            >
              <img src={images[partner?.logo]} alt={partner?.name} />
            </Box>
          </Paper>
          <Typography variant='body2' sx={{ pt: 1 }}>
            {partner?.name}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={linkStyle}
            component='a'
            href={`https://${partner?.link}`}
            target='_blank'
          >
            {partner?.link}
          </Typography>
        </Stack>
      </>
    );
  };

  if (partnerType !== 'standard') {
    return (
      <Box ref={sectionRefs['Partners']}>
        <SectionHeader props={localProps}></SectionHeader>
        <Box
          sx={{ pt: 3, pl: 12, pr: 12, pb: 6 }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid container>
            {partners.map((partner, index) => {
              const Flag = Flags[partner.code];
              return (
                <Grid
                  justifyContent='center'
                  alignItems='center'
                  container
                  item
                  xlg={2}
                  lg={2}
                  sm={4}
                  xs={6}
                  md={3}
                  key={index}
                >
                  <Grid container>
                    <Grid
                      item
                      justifyContent='center'
                      alignItems='center'
                      container
                      xs={12}
                    >
                      <Typography
                        sx={{
                          width: '50px',
                          maxWidth: '50px',
                          border: '1px solid #000',
                          p: 0,
                          m: 0,
                          lineHeight: 0,
                        }}
                      >
                        <Flag />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ pb: 2, pt: 1 }}
                      justifyContent='center'
                      alignItems='center'
                      container
                    >
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {partner.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    );
  }

  if (partnerType === 'standard') {
    return (
      <Box ref={sectionRefs['Partners']}>
        <SectionHeader props={localProps}></SectionHeader>
        <Box
          sx={{ pt: 3, pl: 12, pr: 12, pb: 6 }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid container>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[0]} row={1} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[1]} row={1} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[2]} row={1} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[3]} row={2} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[4]} row={2} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <Partner partner={partners[5]} row={2} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
};

export default Partners;
