// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EastIcon from '@mui/icons-material/East';

/**
 * Access Section
 * @param {object} props
 * @returns Component
 */
const Access = (props) => {
  const { sectionRefs, config } = props.props;
  const { registryLink, name, leadership, showAccessSections, accessSections } =
    config;
  const theme = useTheme();
  const orange = theme.palette.orange;
  const oliveGreen = theme.palette.oliveGreen;
  const oliveGreenFade = theme.palette.oliveGreenFade;
  const lightOliveGreen = theme.palette.lightOliveGreen;
  const localProps = {
    title: 'Access',
    slogan: 'Easily Request Access',
    description: `Requesting access to the ${name} Registry is as easy as completing an online form. Upon submitting the request, complete the email confirmation to initiate the request review process. You will be notified by email once the review process has been completed and a decision has been made.`,
  };

  /* Paper Style */
  const paperStyle = {
    background: `linear-gradient(#fff 50%, ${lightOliveGreen} 80%)`,
    color: 'black',
    p: 2.8,
    borderRadius: '15px',
    border: `1px solid ${orange}`,
  };

  const linkStyle = {
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.orange,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  };

  /**
   * Access Description List Item
   * @param {string} desc
   * @returns component
   */
  const AccessDescriptionListItem = ({ desc }) => {
    const listIcon = { alignItems: 'right', justifyContent: 'right' };
    return (
      <ListItem disableGutters sx={{ m: 0, p: 0 }}>
        <ListItemIcon sx={listIcon}>
          <EastIcon sx={{ color: orange, mr: 1 }} fontSize='small' />
        </ListItemIcon>
        <ListItemText
          primary={<Typography variant='subtitle2'>{desc}</Typography>}
        />
      </ListItem>
    );
  };

  /**
   * Access Description
   * @param {string} type
   * @returns
   */
  const AccessDescription = ({ type, description, href, access }) => {
    return (
      <Stack>
        <Typography variant='body2' sx={{ pb: 1 }}>
          Getting {type} Access
        </Typography>
        <Typography variant='subtitle2'>
          Contact {name} support through the registry account profile with a
          message containing the following details:
        </Typography>
        <List>
          <AccessDescriptionListItem desc='Reason for your request for access' />
          <AccessDescriptionListItem desc={`Intended usage for ${type}`} />
        </List>
        <Typography variant='subtitle2'>
          {description}&nbsp;
          <Typography component='a' href={href} target='_blank' sx={linkStyle}>
            {access}
          </Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <Box
      ref={sectionRefs['Access']}
      sx={{ background: `linear-gradient(${oliveGreen}, ${oliveGreenFade})` }}
    >
      <SectionHeader props={localProps} color='white' />
      <Grid container sx={{ p: 6, pt: 2, pb: 8 }} spacing={5}>
        <Grid
          item
          xs={12}
          sx={{ minHeight: 30, pb: 1 }}
          container
          alignItems='center'
          justifyContent='center'
        >
          <Button
            variant='orangeContained'
            component='a'
            href={`${registryLink}/signup`}
          >
            Request Registry Access
          </Button>
        </Grid>
        {showAccessSections && (
          <>
            {accessSections.map((access) => (
              <Grid item md={6} sm={12}>
                <Paper sx={paperStyle}>
                  <AccessDescription
                    type={access.type}
                    description={access.description}
                    href={access.href}
                    access={access.access}
                  />
                </Paper>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Access;
