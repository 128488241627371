// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/**
 * Slider Section
 * @returns Component
 */
const Slider = (props) => {
  const { config } = props.props;
  const { styles, sliderImages } = config;
  const images = sliderImages;
  const imagesCount = images?.length > 0 ? images.length - 1 : 0;
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState({
    element: 0,
    src: imagesCount ? images[0] : '',
  });

  const sliderLeft = () => {
    const prev = imageSrc.element - 1 < 0 ? imagesCount : imageSrc.element - 1;
    setImageSrc({ element: prev, src: images[prev] });
  };
  const sliderRight = () => {
    const next = imageSrc.element + 1 > imagesCount ? 0 : imageSrc.element + 1;
    setImageSrc({ element: next, src: images[next] });
  };

  return (
    <>
      {imagesCount > 0 && (
        <Box sx={{ ...styles.angle, pt: 0, mt: -0.05 }}>
          <Paper
            component={Stack}
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ height: '100%', color: theme.palette.oliveGreenFade }}
            elevation={0}
          >
            <ArrowBackIosIcon
              onClick={sliderLeft}
              sx={{ fontSize: 70, color: '#fff' }}
            />
            <Box
              sx={{
                height: '50h',
                maxHeight: '375px',
                width: '50vw',
                maxWidth: '625px',
              }}
            >
              <img
                src={imageSrc['src']}
                alt={`Slider-${imageSrc['element']}`}
                height='100%'
                width='100%'
              />
            </Box>
            <ArrowForwardIosIcon onClick={sliderRight} sx={{ fontSize: 70 }} />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Slider;
