// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import {
  Access,
  Concepts,
  Contact,
  Features,
  Mission,
  Partners,
  Hero,
  Metrics,
  Slider,
  Newsletter,
  Arcs,
} from '../index';
import { DialogView } from '../../components/DialogView';

/**
 * Homepage
 * @returns Component
 */
const Home = (props) => {
  let { section, emailAddress, unsubscribeValidation, activeStatus } =
    useParams();
  const localProps = props.props;
  const { scrollToSection, config } = localProps;
  useEffect(() => {
    if (section) {
      const event = {
        target: { id: section.charAt(0).toUpperCase() + section.slice(1) },
      };
      scrollToSection(event);
    }
  }, []);

  return (
    <>
      <DialogView
        activeStatus={activeStatus}
        emailAddress={emailAddress}
        unsubscribeValidation={unsubscribeValidation}
      />
      <Hero props={localProps} />
      <Box
        sx={{
          backgroundColor: '#fff',
          color: '#000',
        }}
      >
        <Mission props={localProps} />
        <Metrics props={localProps} />
        {config.showArcs && <Arcs props={localProps}></Arcs>}
        <Features props={localProps} />
        <Slider props={localProps} />
        <Concepts props={localProps} />
        <Access props={localProps} />
        <Partners props={localProps} />
        <Contact props={localProps} />
        <Newsletter props={localProps} />
      </Box>
    </>
  );
};

export default Home;
