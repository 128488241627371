// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React, { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { postContact, verifyContactForm } from '../../helpers';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

/**
 * Contact Section
 * @param {object} props
 * @returns Component
 */
const Contact = (props) => {
  const { sectionRefs, config } = props.props;
  const { styles, name, recaptchaPublicKey } = config;
  const theme = useTheme();
  const oliveGreen = theme.palette.oliveGreen;
  const oliveGreenFade = theme.palette.oliveGreenFade;
  const localProps = {
    title: 'Contact Us',
    slogan: 'Questions and Concerns',
    description: `Reach out to the ${name} website managers with questions or concerns that you were not able to answer or resolve by reviewing the pages. For more information on creating a ${name} Registry account, please instead Visit the ${name} Registry.`,
  };
  const fieldStyle = {
    width: '100%',
    bgcolor: 'white',
    input: { color: 'black' },
  };
  const labelStyle = {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
    pb: 1,
  };
  // Default State Values
  const contactFormFieldsDefault = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  const contactFormResultDefault = {
    message: '',
    severity: '',
  };
  const contactFormDefault = {
    fields: contactFormFieldsDefault,
    valid: false,
    submitted: false,
    result: contactFormResultDefault,
    clicked: false,
  };

  /* Field Management */
  const [contactForm, setContactForm] = useState(contactFormDefault);

  /**
   * Handle Field State Updates
   * @param {event} event
   */
  const handleFieldUpdate = (event) => {
    const { name, value } = event.target;
    setContactForm({
      ...contactForm,
      fields: { ...contactForm.fields, [name]: value },
    });
  };

  useEffect(() => {
    setContactForm({ ...contactForm, valid: true, clicked: true });
    const formFields = Object.entries(contactForm.fields);
    const fieldsFilled = !formFields.some((f) => f[1] === '');
    // Field Validation
    if (fieldsFilled) {
      if (verifyContactForm(contactForm.fields)) {
        setContactForm({ ...contactForm, valid: false, clicked: false });
      } else {
        setContactForm({ ...contactForm, valid: true, clicked: true });
      }
    }
  }, [contactForm.fields]);

  const ReCaptcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = useCallback(async () => {
      setContactForm({ ...contactForm, clicked: true });
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha();
      const result = await postContact({
        token,
        name: contactForm.fields.name,
        email: contactForm.fields.email,
        subject: contactForm.fields.subject,
        message: contactForm.fields.message,
      });
      if (result?.message === 'success') {
        setContactForm({
          ...contactForm,
          valid: false,
          clicked: true,
          fields: contactFormFieldsDefault,
          submitted: true,
          result: {
            message: 'Message submitted successfully',
            severity: 'success',
          },
        });
      } else {
        setContactForm({
          ...contactForm,
          valid: false,
          clicked: false,
          submitted: true,
          result: {
            message: 'Message submission failed',
            severity: 'error',
          },
        });
      }
      setTimeout(() => {
        setContactForm({ ...contactFormDefault, valid: true, clicked: false });
      }, 5000);
    }, [executeRecaptcha]);
    return (
      <Button
        variant='orangeContained'
        sx={{ p: 1, pl: 6, pr: 6 }}
        onClick={handleReCaptchaVerify}
        disabled={contactForm.clicked}
      >
        Send Message
      </Button>
    );
  };

  return (
    <>
      <Box
        ref={sectionRefs['Contact']}
        sx={{ background: `linear-gradient(${oliveGreen}, ${oliveGreenFade})` }}
      >
        <SectionHeader props={localProps} color='white'></SectionHeader>

        <FormControl>
          {recaptchaPublicKey && (
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaPublicKey}>
              <Grid container sx={{ p: 6, pt: 0 }} spacing={2}>
                <Grid item xs={6}>
                  <Typography sx={labelStyle}>Name</Typography>
                  <TextField
                    id='name'
                    name='name'
                    variant='outlined'
                    sx={fieldStyle}
                    autoComplete='off'
                    required
                    onChange={handleFieldUpdate}
                    value={contactForm.fields.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={labelStyle}>Email Address</Typography>
                  <TextField
                    id='email'
                    name='email'
                    variant='outlined'
                    sx={fieldStyle}
                    autoComplete='off'
                    required
                    onChange={handleFieldUpdate}
                    value={contactForm.fields.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={labelStyle}>Subject</Typography>
                  <TextField
                    id='subject'
                    name='subject'
                    variant='outlined'
                    sx={fieldStyle}
                    autoComplete='off'
                    required
                    onChange={handleFieldUpdate}
                    value={contactForm.fields.subject}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={labelStyle}>Message</Typography>
                  <TextField
                    id='message'
                    name='message'
                    multiline
                    rows={4}
                    sx={fieldStyle}
                    InputProps={{ style: { color: '#000' } }}
                    autoComplete='off'
                    required
                    onChange={handleFieldUpdate}
                    value={contactForm.fields.message}
                  />
                </Grid>
                <Grid item xs={6} container>
                  {recaptchaPublicKey && (
                    <>
                      <ReCaptcha />
                    </>
                  )}
                </Grid>
                <Grid item xs={6} container justifyContent='flex-end'>
                  {contactForm.submitted && (
                    <Alert
                      variant='outlined'
                      severity={contactForm.result.severity}
                      sx={{
                        fontSize: 8,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {contactForm.result.message}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </GoogleReCaptchaProvider>
          )}
        </FormControl>
      </Box>
      <Box sx={styles.angleBottom}></Box>
    </>
  );
};

export default Contact;
