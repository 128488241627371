// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
const countUpModule = await import('countup.js');

/**
 * Metrics Section
 * @param {object} props
 * @returns Component
 */
const Metrics = (props) => {
  const { config } = props.props;
  const { ref, inView } = useInView();
  let countUpAnim0, countUpAnim1, countUpAnim2, countUpAnim3;
  const metrics = [];
  const metricsCount = config.metrics?.length || 0;
  const metricsColumns = metricsCount === 4 ? 3 : 4;
  // Metric Animation Store
  const countUpAnims = {
    a0: countUpAnim0,
    a1: countUpAnim1,
    a2: countUpAnim2,
    a3: countUpAnim3,
  };
  // Metric References for Count Update
  const refs = {
    r0: useRef([]),
    r1: useRef([]),
    r2: useRef([]),
    r3: useRef([]),
  };
  // Create Metrics Listing from Config Details
  if (metricsCount) {
    config.metrics.forEach((value, i) =>
      metrics.push({
        ...value,
        store: countUpAnims[`a${i}`],
        ref: refs[`r${i}`],
      })
    );
  }

  // When Scroll in View then Activate The Count Up Animation
  useEffect(() => {
    if (inView) {
      metrics.map((metric) => {
        return initCountUp(metric);
      });
    }
  }, [inView]);

  /**
   * Count Up Function
   * @param {object} metric
   */
  async function initCountUp(metric) {
    let { store, ref, count } = metric;
    store = new countUpModule.CountUp(ref.current, count);
    if (!store.error) {
      store.start();
    }
  }

  return (
    <Box>
      <Grid container spacing={3} sx={{ pt: 7, pb: 8 }}>
        {metrics.map((metric, index) => (
          <Grid item md={metricsColumns} sm={6} xs={6} key={metric.title}>
            <Stack
              spacing={1}
              alignItems='center'
              justifyContent='center'
              key={index}
              ref={ref}
            >
              <Typography variant='h3' sx={{ lineHeight: 0.8 }}>
                <Stack direction='row'>
                  <Typography
                    variant='h3'
                    sx={{ lineHeight: 0.8 }}
                    ref={metric.ref}
                  >
                    {metric.count}
                  </Typography>
                  <Typography variant='h3' sx={{ lineHeight: 0.8 }}>
                    +
                  </Typography>
                </Stack>
              </Typography>
              <Typography variant='body2'>{metric.title}</Typography>
              <Typography variant='subtitle2' sx={{ lineHeight: 0 }}>
                {metric.slogan}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Metrics;
