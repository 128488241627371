// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SectionHeader } from '../../components';
import { CenteredGrid } from '../../components/CenteredGrid';
import parse from 'html-react-parser';

/**
 * Faq Section
 * @param {object} props
 * @returns Component
 */
const Faq = (props) => {
  const { isSecondaryPage, scrollToSection, config } = props.props;
  const { name, license } = config;
  const theme = useTheme();
  const navigate = useNavigate();

  /* Loads Base Section and Scrolls to Section */
  const handleScrollToSection = (event) => {
    if (isSecondaryPage) {
      navigate(`/${event.target.id}`);
    } else {
      scrollToSection(event);
    }
  };

  const linkStyle = {
    fontWeight: 300,
    fontSize: 14,
    color: theme.palette.orange,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  };

  const localProps = {
    title: 'F.A.Q',
    slogan: 'Frequently Asked Questions',
    description: `The F.A.Q section provides a breakdown of the most common questions and answers from users for a quick way to learn about the ${name} Registry.`,
  };
  const faqs = [
    {
      category: 'Background',
      faqs: [
        {
          id: 0,
          question: `What is offered within the ${name} ecosystem?`,
          answer: (
            <ol>
              <li>
                An “App Store”-like registry which makes it possible for
                military-systems developers to offer, discover, and procure
                state-of-the-art ROS software components.
              </li>
              <li>
                Tools which promote development of more reliable and secure
                software components.
              </li>
              <li>
                Business guidance to facilitate collaboration and re-use in
                life-cycle development to reduce software development costs.
              </li>
            </ol>
          ),
        },
        {
          id: 1,
          question: `What is ${name}?`,
          answer: (
            <>
              {name} is a software ecosystem for military RAS based on open ROS.
              It provides a trusted community of Government and Industry
              contributors with the means to collectively cultivate a federated
              body of re-useable, ROS-compatible software for developing defense
              robotic systems.
            </>
          ),
        },
        {
          id: 2,
          question: 'What is ROS?',
          answer: (
            <>
              The Robot Operating System (ROS) is a flexible framework for
              writing robot software. It is a collection of tools, libraries,
              and conventions that aim to simplify the task of creating complex
              and robust robot behavior across a wide variety of robotic
              platforms.
            </>
          ),
        },
      ],
    },
    {
      category: 'Registry Use',
      faqs: [
        {
          id: 3,
          question: 'Two-Factor Authentication (2FA)',
          answer: (
            <>
              The following instructions assume Google Authenticator will be
              used for Two-Factor Authorization [2FA], but there are many
              similar apps that are available.
              <br />
              <ul>
                <li>
                  Download Google Authenticator on your smartphone or tablet
                  from the App Store. Additional instructions can be found&nbsp;
                  <Typography
                    component='a'
                    href='https://support.google.com/accounts/answer/1066447'
                    target='_blank'
                    sx={linkStyle}
                  >
                    here
                  </Typography>
                  .
                </li>
                <li>
                  Open the authentication app and scan the QR code shown on the
                  {name} web page.
                </li>
                <li>
                  Enter the 6 digit code shown in the app on the {name} page, in
                  the Secret Token input.
                </li>
              </ul>
              You have now enabled 2FA for your {name} account. You will have to
              enter the code shown on your authentication app every time you log
              in to the {name} website. If you have any further questions,
              please contact support using the&nbsp;
              <Typography
                component='a'
                id='Contact'
                onClick={handleScrollToSection}
                sx={linkStyle}
              >
                Contact form
              </Typography>
              .
            </>
          ),
        },
        {
          id: 4,
          question: 'Who should I contact if I have questions?',
          answer: (
            <>
              Contact the {name} Registry support using the “Contact Support”
              section under the profile of an active account, or if an account
              isn’t yet setup, use the “Contact” form and your question will be
              redirected to the appropriate person to help you.
            </>
          ),
        },
        {
          id: 5,
          question: 'How do I register a ROS component?',
          answer: (
            <>
              Currently in phase I of beta testing we have disabled population
              of the registry in order to refine features and maintain stable
              content during testing. All beta users will be notified when the
              registry is open for submissions.
            </>
          ),
        },
        {
          id: 6,
          question: `What are the licensing terms for the ROS components registered with ${name}?`,
          answer: (
            <>
              The license for each software component is indicated in its
              registry entry. It is up to the developer of the ROS component to
              define the licensing terms, and all procurement is done directly
              with that developer. Many of the registered components will be
              Government Purpose Rights (GPR) or even open-source. For more
              information about licensing terms, contact the maintainer
              associated with the registry entry.
            </>
          ),
        },
      ],
    },
  ];
  if (license) {
    faqs.push({
      category: 'License',
      faqs: [
        {
          id: 7,
          question: 'ROS 2 License',
          answer: parse(license),
        },
      ],
    });
  }

  return (
    <Box sx={{ bgcolor: '#fff', p: 6, pt: '52px', color: 'black' }}>
      <SectionHeader props={localProps} />
      <Grid container spacing={2}>
        {faqs.map((faqs) => (
          <>
            <CenteredGrid>
              <Typography
                variant='h4'
                sx={{ color: 'black', pt: 2 }}
                gutterBottom
              >
                <Typography
                  component='span'
                  sx={{
                    color: theme.palette.orange,
                    fontSize: '2.0243rem',
                    fontWeight: 400,
                  }}
                >
                  {name}&nbsp;
                </Typography>
                {faqs.category}
              </Typography>
            </CenteredGrid>
            {faqs.faqs.map((faq) => (
              <Grid item xs={12}>
                <Typography variant='h6'>{faq.question}</Typography>
                <Typography variant='body1'>{faq.answer}</Typography>
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default Faq;
