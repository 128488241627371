// (C) 2024 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.

const cfg = { ...process.env, ...window['config'] };
export const config = {
  server: cfg.REACT_APP_SERVER,
  name: cfg.REACT_APP_NAME,
  logoText: cfg.REACT_APP_LOGO_TEXT,
  registryLink: cfg.REACT_APP_REGISTRY_LINK,
  footerContent: cfg.REACT_APP_FOOTER_CONTENT,
  siteTitle: cfg.REACT_SITE_TITLE,
  theme: cfg.REACT_APP_THEME,
  styles: cfg.REACT_APP_STYLES,
  leadership: cfg.REACT_APP_LEADERSHIP,
  license: cfg.REACT_APP_LICENSE,
  showArcs: cfg.REACT_APP_SHOW_ARCS_SECTION,
  partnerType: cfg.REACT_APP_PARTNER_TYPE,
  partners: cfg.REACT_APP_PARTNERS,
  partnerSlogan: cfg.REACT_APP_PARTNER_SLOGAN,
  partnerDescription: cfg.REACT_APP_PARTNER_DESCRIPTION,
  showAccessSections: cfg.REACT_APP_SHOW_ACCESS_SECTIONS,
  accessSections: cfg.REACT_APP_ACCESS,
  recaptchaPublicKey: cfg.RECAPTCHA_PUBLIC_KEY,
  opsecNumber: cfg.OPSEC_NUMBER,
  sliderImages: cfg.SLIDER_IMAGES,
  metrics: cfg.REACT_APP_METRICS,
};
